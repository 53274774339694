import { ReactNode, useState } from 'react';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { useParams } from 'react-router-dom';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { Formatter } from 'dg-web-shared/lib/Date';
import { DateTime } from 'luxon';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter';
import {
    ParkingaboProductListItem,
    ParkingaboProductType,
    productTypeHasPrice,
} from '../../../shared/ParkingaboProductModels';
import { ErrorAlert } from 'dg-web-shared/common/components/material-ui/ErrorAlert';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { TabContext, TabPanel } from '@mui/lab';
import { ProductDetailReservation } from './ProductsDetailOutletReservation';
import { ProductTransactionsList } from './ProductsDetailOutletTransactions';
import { ProductDetailSubscription } from './ProductsDetailOutletSubscription';
import { ProductDetailGuestReservation } from './ProductsDetailOutletGuestReservation';
import { VehicleType } from './ProductsConfigurationOutlet';
import { ProductDetailPermit } from './ProductsDetailOutletPermitUntilRevocation.tsx';

export function ProductsDetailOutlet({
    products,
    refetchProducts,
}: AuthedRouteCompProps) {
    const [selectedTab, setSelectedTab] = useState('detail');
    const { contractId } = useParams<{ contractId: string }>();
    const product = products.find(
        p => contractId && p.contractId === parseInt(contractId, 10),
    );
    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={'..'}
            render={() => (
                <ParkingaboLayoutWithHeader
                    backTo={'..'}
                    title={
                        <Localized
                            de="Produkt"
                            fr="Produit"
                            it="Prodotto"
                            en="Product"
                        />
                    }
                    noGutter
                    scrollable
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                    >
                        <TabContext value={selectedTab}>
                            <Box>
                                <Tabs
                                    value={selectedTab}
                                    onChange={(_, v) => setSelectedTab(v)}
                                    variant="fullWidth"
                                    sx={{
                                        backgroundColor: theme =>
                                            theme.palette.primary.light,
                                    }}
                                >
                                    <Tab
                                        label={
                                            <Localized
                                                de="Details"
                                                fr="Détails"
                                                it="Dettagli"
                                                en="Details"
                                            />
                                        }
                                        value={'detail'}
                                    />
                                    <Tab
                                        label={
                                            <Localized
                                                de="Parkvorgänge"
                                                fr="Procédures"
                                                it="Soste"
                                                en="Procedures"
                                            />
                                        }
                                        value={'contracts'}
                                        disabled={
                                            !product ||
                                            DateTime.fromISO(
                                                product.validFrom,
                                            ) > DateTime.now()
                                        }
                                    />
                                </Tabs>
                            </Box>
                            <TabPanel value={'detail'} sx={{ flexGrow: 1 }}>
                                {product ? (
                                    <ProductDetail
                                        product={product}
                                        refetchProducts={refetchProducts}
                                    />
                                ) : (
                                    <ErrorAlert>
                                        <Localized
                                            de="Produkt nicht gefunden."
                                            fr="Produit pas trouvé."
                                            it="Prodotto non trovato."
                                            en="Product not found."
                                        />
                                    </ErrorAlert>
                                )}
                            </TabPanel>
                            <TabPanel
                                value={'contracts'}
                                sx={{
                                    height: '100%',
                                    paddingTop: '0px',
                                }}
                            >
                                {contractId && (
                                    <ProductTransactionsList
                                        productId={contractId}
                                    />
                                )}
                            </TabPanel>
                        </TabContext>
                    </Box>
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}

function ProductDetail({
    product,
    refetchProducts,
}: {
    product: ParkingaboProductListItem;
    refetchProducts: () => void;
}): JSX.Element {
    switch (product.type) {
        case ParkingaboProductType.RESERVATION:
            return (
                <ProductDetailReservation
                    product={product}
                    refetchProducts={refetchProducts}
                />
            );
        case ParkingaboProductType.SUBSCRIPTION:
            return (
                <ProductDetailSubscription
                    product={product}
                    refetchProducts={refetchProducts}
                />
            );
        case ParkingaboProductType.GUEST_RESERVATION:
            return (
                <ProductDetailGuestReservation
                    product={product}
                    refetchProducts={refetchProducts}
                />
            );
        case ParkingaboProductType.PERMIT_UNTIL_REVOCATION:
        case ParkingaboProductType.PERMIT_FREE_FIXED_DURATION:
            return <ProductDetailPermit product={product} />;
    }
}

export function ProductOverviewList({
    product,
}: {
    product: ParkingaboProductListItem;
}) {
    return (
        <ParkingaboOverlineList.Body sx={{ flexGrow: 1 }}>
            <ParkingaboOverlineList.Item
                label={
                    <Localized
                        de="Beschreibung"
                        fr="Description"
                        it="Descrizione"
                        en="Description"
                    />
                }
            >
                <Localized {...product.name} />
            </ParkingaboOverlineList.Item>
            {product.remark && (
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Bemerkungen"
                            fr="Remarques"
                            it="Osservazioni"
                            en="Remarks"
                        />
                    }
                >
                    <span style={{ whiteSpace: 'pre' }}>{product.remark}</span>
                </ParkingaboOverlineList.Item>
            )}
            <ParkingaboOverlineList.Item
                label={
                    product.zones.length > 1 ? (
                        <Localized
                            de="Parkings"
                            fr="Parkings"
                            it="Parcheggi"
                            en="Parkings"
                        />
                    ) : (
                        <Localized
                            de="Parking"
                            fr="Parking"
                            it="Parcheggio"
                            en="Parking"
                        />
                    )
                }
            >
                {product.zones.map(zone => (
                    <Box
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}
                        key={zone.zoneId}
                    >
                        {[zone.name, zone.city].join(' – ')}
                    </Box>
                ))}
            </ParkingaboOverlineList.Item>
            <ParkingaboOverlineList.Item
                label={
                    <Localized de="Beginn" fr="Début" it="Inizio" en="Begin" />
                }
            >
                {Formatter.dayMonthYearHourMinute(
                    DateTime.fromISO(product.validFrom),
                )}
            </ParkingaboOverlineList.Item>
            <ParkingaboOverlineList.Item
                label={<Localized de="Ende" fr="Fin" it="Fine" en="End" />}
            >
                {product.validTo ? (
                    Formatter.dayMonthYearHourMinute(
                        DateTime.fromISO(product.validTo),
                    )
                ) : (
                    <Localized
                        de="bis auf Widerruf"
                        fr="jusqu'à révocation"
                        it="fino a revoca"
                        en="until recall"
                    />
                )}
            </ParkingaboOverlineList.Item>
            <VehicleType vehicleType={product.vehicleType} />
            {product.info && (
                <ParkingaboOverlineList.Item
                    label={
                        <Localized de="Info" fr="Info" it="Info" en="Info" />
                    }
                >
                    <Localized {...product.info} />
                </ParkingaboOverlineList.Item>
            )}
            {productTypeHasPrice(product.type) && (
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Preis"
                            fr="Prix"
                            it="Prezzo"
                            en="Price"
                        />
                    }
                >
                    {currencyCentsToLocalPrice('de', product.priceRappen)}
                    {product.type === ParkingaboProductType.SUBSCRIPTION && (
                        <Localized
                            de=" / Monat"
                            fr=" / mois"
                            it=" / mese"
                            en=" / month"
                        />
                    )}
                </ParkingaboOverlineList.Item>
            )}
        </ParkingaboOverlineList.Body>
    );
}

export function ProductDetailBody({ children }: { children: ReactNode }) {
    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}
            sx={{ height: '100%' }}
        >
            {children}
        </Stack>
    );
}
