import { MaterialThemeProvider } from 'dg-web-shared/common/hooks/MaterialThemeProvider';
import { useLanguage } from 'dg-web-shared/common/hooks/LanguageProvider';
import { parkingaboDefaultMuiTheme } from './ParkingaboDefaultMuiTheme.tsx';

export function ParkingaboThemeProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const { language } = useLanguage();

    return (
        <MaterialThemeProvider
            theme={parkingaboDefaultMuiTheme}
            language={language}
        >
            {children}
        </MaterialThemeProvider>
    );
}
