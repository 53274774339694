import { CssBaseline } from '@mui/material';

import { ParkingaboApiMiddlewereContextProvider } from './api/ParkingaboApi';
import { AppRoutes, useLogin } from './routes/AppRoutes';
import { MainLayout } from './MainLayout';
import { LanguageProvider } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboThemeProvider } from './shared/ParkingaboThemeProvider';
import { envIsLocalDev } from 'dg-web-shared/lib/Environment.ts';

export function App() {
    return (
        <LanguageProvider>
            <ParkingaboApiMiddlewereContextProvider>
                <ActualApp />
            </ParkingaboApiMiddlewereContextProvider>
        </LanguageProvider>
    );
}

function ActualApp() {
    const login = useLogin();
    if (envIsLocalDev()) {
        document.title = 'LOCAL Parkingabo';
    }

    return (
        <ParkingaboThemeProvider>
            <MainLayout>
                <CssBaseline />
                <AppRoutes login={login} />
            </MainLayout>
        </ParkingaboThemeProvider>
    );
}
