import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports.ts';
import ReactMarkdown from 'react-markdown';
import { useLanguage } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import { Box } from '@mui/material';

export function ParkingaboPrivacy() {
    const { language } = useLanguage();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <ReactMarkdown>
                {Legal.privacyPolicyMarkdown[language]}
            </ReactMarkdown>
        </Box>
    );
}
