import {
    ParkingaboProductListItem,
    ParkingaboProductType,
} from '../../../shared/ParkingaboProductModels.ts';
import {
    ProductDetailBody,
    ProductOverviewList,
} from './ProductsDetailOutlet.tsx';

export function ProductDetailPermit({
    product,
}: {
    product: ParkingaboProductListItem;
}) {
    if (
        product.type !== ParkingaboProductType.PERMIT_UNTIL_REVOCATION &&
        product.type !== ParkingaboProductType.PERMIT_FREE_FIXED_DURATION
    ) {
        throw new Error('product type must be of type permit');
    }

    return (
        <ProductDetailBody>
            <ProductOverviewList product={product} />
        </ProductDetailBody>
    );
}
